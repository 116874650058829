<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import complex from '../../../../compoment/complex/complex';

formCreate.component('complex', complex);
let formData;
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
    };
  },

  mounted() {
    this.getFormRule('goods-replenish-form');
  },

  methods: {
    setRule(v) {
      const rowData = v;
      if (rowData.field === 'accountNum') {
        rowData.on = {
          ...rowData.on,
          input: (value) => {
            console.log(value);
            const balance = this.getFieldValue('balanceNum');
            const sourceType = this.getFieldValue('sourceType');
            console.log(sourceType);
            this.setValue({ h: (sourceType === '2' || sourceType === '9') ? balance + value : balance - value });
          },
        };
      }
      if (rowData.field === 'sourceType') {
        rowData.on = {
          ...rowData.on,
          change: (value) => {
            const balance = this.getFieldValue('balanceNum');
            const accountFee = this.getFieldValue('accountFee');
            if (value === '2' || value === '9') {
              this.setValue({ h: balance + accountFee });
            } else {
              this.setValue({ h: balance - accountFee });
            }
          },
        };
      }
      if (rowData.field === 'fileVos') {
        rowData.props.fileMsg = '请上传pdf/word/excel/ppt/png/jpg格式的文件';
        rowData.props.accept = 'pdf,word,xlsx,ppt,png,jpg';
        console.log(rowData);
      }
      return rowData;
    },

    formComplete() {
      if (this.formConfig.poolId) {
        this.getData(this.formConfig.poolId);
      }
    },

    async getData(id) {
      const { result } = await this.$http.post(
        '/dms/dms/repPool/query', { id },
      );
      formData = result;
      this.setValue({
        ...result,
      });
    },

    submit() {
      let params = this.getFormData();
      const path = '/dms/dms/repPoolDetail/save';
      params.poolId = this.formConfig.poolId;
      params.productCode = formData.productCode;
      params = { ...params, ...params.effective };
      if (params.sourceType === '9' && params.accountFee > formData.frozenFee) {
        this.$message.error('调整金额不能大于冻结金额');
        return;
      }
      request.post(path, params).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功！');
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$message.error(res.messge);
        }
      });
    },
  },
};
</script>
